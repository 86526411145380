import * as React from 'react'
import { Link } from 'gatsby'
import {
  appContainer,
  appFrame,
  svgBox,
  svgIcon,
  appTitle,
  ca7anHex, ca7anPlus,
  newsletterSymbolLine,
  lightSwitchSymbolLine,
  prodSymbolCircle,
  ellipsesDot
} from './apps.module.css'

const apps = [
  {name: "CA7AN", link: "https://www.ca7an.evangedrich.com", svg: <g className={svgIcon}>
    <polygon points="90,50 70,85 30,85 10,50 30,15 70,15" strokeLinejoin="round" className={ca7anHex}></polygon>
    <path d="M40,50 60,50 M50,40 50,60" strokeLinecap="round" className={ca7anPlus}></path>
  </g>},
  {name: "Newsletter", link: "/apps/newsletter", svg: <g className={svgIcon}>
    <rect x="17.5" y="17.5" width="65" height="65" rx="3"/>
    <path className={newsletterSymbolLine} d="M30,30 70,30" strokeLinecap="round" strokeDasharray="126" />
    <path className={newsletterSymbolLine} d="M30,40 70,40" strokeLinecap="round" strokeDasharray="80" />
    <path className={newsletterSymbolLine} d="M30,50 70,50" strokeLinecap="round" strokeDasharray="112" />
    <path className={newsletterSymbolLine} d="M30,60 70,60" strokeLinecap="round" strokeDasharray="45" />
    <path className={newsletterSymbolLine} d="M30,70 70,70" strokeLinecap="round" strokeDasharray="218" />
  </g>},
  {name: "Lights", link: "/apps/lights", svg: <g className={svgIcon}>
    <circle cx="50" cy="50" r="33" />
    <line className={lightSwitchSymbolLine} x1="50" y1="28" x2="50" y2="72" strokeLinecap="round" />
  </g>},
  {name: "Decide", link: "/apps/decide", svg: <g className={svgIcon}>
    <ellipse className={prodSymbolCircle} cx="50" cy="59" rx="5" ry="5" />
    <polygon points="50,18 13,81 88,81" strokeLinejoin="round" />
  </g>},
  {name: "In Progress", link: "/apps", svg: <g className={svgIcon}>
    <ellipse className={ellipsesDot} cx="20" cy="70" rx="4" r="4" />
    <ellipse className={ellipsesDot} cx="50" cy="70" rx="4" r="4" />
    <ellipse className={ellipsesDot} cx="80" cy="70" rx="4" r="4" />
  </g>}
];

const AppFrame = ({ name, link, svg }) => {
  if (link.substring(0,4)==="http") {
    return (
      <a href={link} target="_blank" rel="noreferrer" className={appFrame}>
        <li key={name}>
          <div className={svgBox}>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">{svg}</svg>
          </div>
          <h2 className={appTitle}>{name}</h2>
        </li>
      </a>
    )
  } else {
    return (
      <Link to={link} className={appFrame}>
        <li key={name}>
          <div className={svgBox}>
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">{svg}</svg>
          </div>
          <h2 className={appTitle}>{name}</h2>
        </li>
      </Link>
    )
  }
}

const AppContainer = ({ children }) => {
  return (
    <ul className={appContainer}>
      {apps.map(app => (
        <AppFrame key={app.name} name={app.name} link={app.link} svg={app.svg}></AppFrame>
      ))}
    </ul>
  )
}

export default AppContainer
