// extracted by mini-css-extract-plugin
export var appContainer = "apps-module--app-container--8LKNr";
export var appFrame = "apps-module--app-frame--cpwx6";
export var svgBox = "apps-module--svg-box--t0sTf";
export var svgIcon = "apps-module--svg-icon--yl4S9";
export var appTitle = "apps-module--app-title--Et6i8";
export var ca7anHex = "apps-module--ca7an-hex--uTxUO";
export var ca7anPlus = "apps-module--ca7an-plus--YIhSM";
export var newsletterSymbolLine = "apps-module--newsletter-symbol-line--yuiFw";
export var linedraw = "apps-module--linedraw--fF50g";
export var lightSwitchSymbolLine = "apps-module--lightSwitch-symbol-line--CtlJC";
export var prodSymbolCircle = "apps-module--prod-symbol-circle--6gYjO";
export var rattle = "apps-module--rattle--FDA0u";
export var ellipsesDot = "apps-module--ellipses-dot--ZTUMn";
export var jumplipses = "apps-module--jumplipses--Ygdp5";