import * as React from 'react'
import AppContainer from '../components/app-container'

const Apps = () => {
  return (
    <div>
      <title>Apps &ndash; Evan Gedrich</title>
      <AppContainer></AppContainer>
    </div>
  )
}

export default Apps
